<template>
  <div class="page-box">
    <div class="fl-jcsb-aic mb-10">
      <el-radio-group
        v-model="readStatus"
        size="medium"
        class="mb20"
        @change="handleLabel"
      >
        <el-radio-button label="1">事件详情</el-radio-button>
        <el-radio-button label="2">路线详情</el-radio-button>
        <el-radio-button label="3">策略详情</el-radio-button>
      </el-radio-group>
      <el-button type="info" size="medium" @click="lastPage">关闭</el-button>
    </div>
    <template>
      <components :is="emerTypeComp"></components>
    </template>
  </div>
</template>

<script>
import EventDetails from "./components/eventDetails";
import RouteDetails from "./components/routeDetails";
import StrategyDetails from "./components/strategyDetails";
export default {
  components: { EventDetails, RouteDetails, StrategyDetails },
  props: {},
  data() {
    return {
      readStatus: "1",
      emerTypeComp: "EventDetails",
    };
  },
  watch: {},
  mounted() {},
  methods: {
    handleLabel() {
      if (this.readStatus == "1") {
        this.emerTypeComp = "EventDetails";
      } else if (this.readStatus == "2") {
        this.emerTypeComp = "RouteDetails";
      } else {
        this.emerTypeComp = "StrategyDetails";
      }
    },
    // 上一页
    lastPage() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  height: 100%;
  // overflow: hidden;
  padding: 19px;
  box-sizing: border-box;
  background: #eef3f6;
}
</style>
