<template>
  <div>
    <div class="search-box">
      <el-form :inline="true" :model="searchForm" size="medium">
        <el-form-item label="" class="">
          <el-input
            v-model="input2"
            class="te"
            placeholder="关键字搜索"
            @change="handleQuery"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-date-picker
            placeholder="发布时间"
            v-model="searchForm.time"
            type="datetime"
          >
          </el-date-picker>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="handleQuery">查询</el-button>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        size="medium"
        class="search-right-btn"
        @click="isShowDialog = true"
        >新增</el-button
      >
    </div>
    <div class="table-box">
      <el-table
        :data="tableData"
        border
        size="medium"
        height="calc(100vh - 10.7rem)"
        style="width: 100%"
      >
        <el-table-column
          align="center"
          label="序号"
          type="index"
          width="80"
        ></el-table-column>
        <el-table-column
          align="center"
          label="路线名称"
          prop="name"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          label="起始路线"
          prop="description"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="warning" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination_box">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :current-page="paramData.pageNo"
          :page-size="paramData.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="formData.id ? '编辑' : '新增'"
      :visible="isShowDialog"
      :destroy-on-close="true"
      @close="btnCancel"
    >
      <!-- 匿名插槽 -->
      <el-form
        ref="addForm"
        label-width="120px"
        :model="formData"
        :rules="rules"
      >
        <el-form-item label="路线名称" prop="name">
          <el-input
            v-model="formData.name"
            style="width: 80%"
            placeholder="路线名称"
          />
        </el-form-item>
        <el-form-item label="起始路线" prop="description">
          <el-input
            v-model="formData.description"
            style="width: 80%"
            placeholder="起始路线"
          />
        </el-form-item>
        <el-form-item label="场所名称" prop="siteName">
          <el-input
            v-model="formData.siteName"
            style="width: 80%"
            placeholder="场所名称"
          />
        </el-form-item>
        <el-form-item label="负责人" prop="chargePerson">
          <el-input
            v-model="formData.chargePerson"
            style="width: 80%"
            placeholder="负责人"
          />
        </el-form-item>
        <el-form-item label="可容纳人数" prop="capacity">
          <el-input
            v-model="formData.capacity"
            style="width: 80%"
            placeholder="人数"
          />
        </el-form-item>
        <el-form-item label="联系电话" prop="telephone">
          <el-input
            v-model="formData.telephone"
            style="width: 80%"
            placeholder="联系电话"
          />
        </el-form-item>
      </el-form>
      <!-- el-dialog有专门放置底部操作栏的 插槽  具名插槽 -->
      <el-row slot="footer" type="flex" justify="center">
        <!-- 列被分为24 -->
        <el-col :span="6">
          <el-button type="primary" size="small" @click="btnOK">确定</el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      isShowDialog: false,
      readStatus: "隐患事件",
      searchForm: {
        SearchValue: "",
      },
      input2: "",
      tableData: [],
      total: 0,
      paramData: {
        SearchValue: "",
        PageNo: 1,
        PageSize: 10,
        TypeCode: "",
      },
      // 定义表单数据
      formData: {
        name: "",
        description: "",
        siteName: "",
        chargePerson: "",
        capacity: "",
        telephone: "",
      },
      // 定义校验规则
      rules: {
        name: [
          { required: true, message: "路线名称不能为空", trigger: "blur" },
        ],
        description: [
          { required: true, message: "起始位置不能为空", trigger: "blur" },
        ],
        siteName: [
          { required: true, message: "场所名称不能为空", trigger: "blur" },
        ],
        chargePerson: [
          { required: true, message: "负责人不能为空", trigger: "blur" },
        ],
        capacity: [
          { required: true, message: "人数不能为空", trigger: "blur" },
        ],
        telephone: [
          { required: true, message: "电话不能为空", trigger: "blur" },
        ],
      },
      options: [],
    };
  },
  watch: {},
  mounted() {
    this.getEmergencyRoutePage(this.paramData);
    this.getInformation();
  },
  methods: {
    //获取列表
    async getEmergencyRoutePage(params) {
      const { data } = await this.api.apiEmergencyRoutePage(params);
      this.tableData = data.rows;
      this.total = data.totalRows;
    },
    async getInformation() {
      const { data } = await this.api.apiSysDictTypeDropDown({
        Code: "hiddanger_type",
      });
      this.options = this.options.concat(data);
    },

    //编辑按钮
    async handleEdit(val) {
      const { data } = await this.api.apiEmergencyRouteDetail({ id: val.id });
      this.formData = data;
      this.formData.id = val.id;
      this.isShowDialog = true;
    },
    //删除
    async handleDelete(val) {
      console.log(val);
      await this.$confirm("确定要删除吗");
      // 发送请求
      await this.api.apiEmergencyRouteDelete({ id: val.id });
      this.$message.success("删除成功");
      this.getEmergencyRoutePage(this.paramData);
    },
    //查询
    handleQuery() {
      this.paramData.SearchValue = this.input2;
      const { data } = this.getEmergencyRoutePage(this.paramData);
      this.tableData = data.rows;
    },
    // 分页操作
    handleSizeChange(val) {
      // this.paramData.PageNo = val;
      // this.getHiddangerPage(this.paramData);
    },
    handleCurrentChange(val) {
      this.paramData.PageNo = val;
      this.getEmergencyRoutePage(this.paramData);
    },
    //编辑确定按钮
    async btnOK() {
      await this.$refs.addForm.validate();
      if (this.formData.id) {
        await this.api.apiEmergencyRouteEdit(this.formData);
      } else {
        await this.api.apiEmergencyRouteAdd(this.formData);
      }
      this.$message.success("操作成功");
      this.isShowDialog = false;
      this.getEmergencyRoutePage(this.paramData);
    },
    //编辑取消按钮
    btnCancel() {
      this.formData = {
        name: "",
        description: "",
        siteName: "",
        chargePerson: "",
        capacity: "",
        telephone: "",
      };
      this.isShowDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  height: 100%;
  // overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
  background: #eef3f6;
}
// .search-box {
//   display: flex;
//   justify-content: space-between;
// }
// .search-right-btn {
//   height: 2.1875rem;
// }
// 表格样式
// .table-box {
//   margin-top: 20px;
// }

// 搜索条样式
.search-box {
  position: relative;
  padding: 15px 15px 0;
  background: #fff;
  border-radius: 10px;
  .search-right-btn {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
/deep/ .el-input__inner {
  color: black !important;
}
.pagination_box {
  display: flex;
  justify-content: flex-end;
}
</style>
